// src/Error.tsx
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Error: React.FC = () => {
  const [stripeCustomerId, setStripeCustomerId] = useState<string | null>(null);

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#F23A29',
    color: 'white',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    flexDirection: "column"
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setStripeCustomerId(params.get('stipeCustomerId'));
  }, []);
  
  const openApp = () => {
    window.location.href = `e2g-mobile-app://error?stipeCustomerId=${stripeCustomerId}`;
  };

  return (
    <div style={containerStyle}>
      <h1>Error!</h1>
      <p>Paymet error.</p>
      <Button style={{ backgroundColor: "#298140", textTransform: "none" }} onClick={openApp} variant="contained">Back to App</Button>
    </div>
  );
};

export default Error;
