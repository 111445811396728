// src/Success.tsx
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import API from './api/API';

const Success: React.FC = () => {

  const [sessionStatus, setSessionStatus] = useState(false);
  const [error, setError] = useState("");
  const [donationAmount, setDonationAmount] = useState(0);
  const [noOfBars, setNoOfBars] = useState(0);
  const [freequency, setFreequency] = useState<string | null>("");
  const [userId, setUserId] = useState<string | null>("");
  const [campaignName, setCampaignName] = useState<string | null>("");
  const [campaignId, setCampaignId] = useState<string | null>("");
  const [donationId, setDonationId] = useState<string | null>("");
  const [accessToken, setAccessToken] = useState<string | null>("");
  const [currency, setCurrency] = useState<string | null>("");

  const [stripeCustomerId, setStripeCustomerId] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [paramsLoaded, setParamsLoaded] = useState(false);

  // Inline CSS styles
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#298140',
    color: 'white',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    flexDirection: "column"
  };

  const openApp = () => {
    window.location.href = `e2g-mobile-app://success?stipeCustomerId=${stripeCustomerId}`;
    window.close();
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('session_id');
    const donationId = params.get('donationId');
    setStripeCustomerId(params.get('stipeCustomerId'));
    setSessionId(sessionId);
    setDonationAmount(Number(params.get('donationAmount')));
    setNoOfBars(Number(params.get('noOfBars')));
    setFreequency(params.get('freequency'));
    setUserId(params.get('userId'));
    setCampaignName(params.get('campaignName'));
    setCampaignId(params.get('campaignId'));
    setDonationId(donationId == "undefined" ? "" : donationId);
    setAccessToken(params.get('accessToken'));
    setCurrency(params.get('currency'));

    setParamsLoaded(true);
  }, []);

  useEffect(() => {
    if (paramsLoaded) {
      validateSession()
    }
  }, [paramsLoaded]);

  const validateSession = async () => {
    try {
      const response = await API(accessToken ? accessToken : "").get(`payments/stripe/validate-session/${sessionId}`);
      if (response.data.success) {
        //Update payment
        const tmpDonation = {
          amount: donationAmount/100,
          numberOfUnits: noOfBars,
          currency: currency?.trim(),
          languageCode: "en",
          frequancey: freequency?.trim(),
          donationDate: new Date(),
          donatedBy: userId==undefined || userId=="undefined" || userId=="" ? undefined : Number(userId),
          active: true,
          donationId: "",
          id: donationId==undefined || donationId=="undefined" || donationId=="" ? undefined : Number(donationId?.trim()),
          label: campaignName?.trim(),
          campaignId: Number(campaignId?.trim()),
          stripeId: stripeCustomerId?.trim(),
          stripeSessionId: sessionId
        };

        if (donationId != "") {
          updateDonation(accessToken ? accessToken : "", tmpDonation);
        } else {
          createDonation(accessToken ? accessToken : "", tmpDonation);
        }
      } else {
        setError(response.data.error || 'Validation failed.');
      }
    } catch (err) {
      console.log(err)
      setError("An error occurred.");
    }
  };

  const createDonation = async (accessToken: string, donation: any) => {
    try {
      const response = await API(accessToken).post(`donations`, donation);
      setSessionStatus(true);
      return response;
    } catch (error) {
      const errorMessage = "An unexpected error occurred. Please try again.";
    }
  }


  const updateDonation = async (accessToken: string, donation: any) => {
    try {
      const response = await API(accessToken).patch(`donations/${donation.id}`, donation);
      setSessionStatus(true);
      return response;
    } catch (error) {
      const errorMessage = "An unexpected error occurred. Please try again.";
    }
  }

  if (!sessionId) {
    return (
      <div style={containerStyle}>
        <p>Invalid Attempt</p>
      </div>
    )
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!sessionStatus) {
    return (
      <div style={containerStyle}>
        <p>Validating your payment...</p>
      </div>
    );
  }


  return (
    <div style={containerStyle}>
      <h1>Success!</h1>
      <p>Your payment was successful.</p>
      <Button style={{ backgroundColor: "#F29F05", textTransform: "none" }} onClick={openApp} variant="contained">Back to App</Button>
    </div>
  );
};

export default Success;
